<template>
  <div class="w-full">
      <label class="vs-input--label" v-if="label" >{{ label }}</label>
      <v-select
        v-model="account"
        :clearable="false"
        :reduce="(option) => option.value"
        :options="options"
        data-vv-as="Conta"
        v-validate.initial="'required'"
        name="account"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
        :appendToBody="appendToBody"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('account')">{{
        errors.first('account')
      }}</span>
  </div>
</template>

<script>
// Store Module
export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    disabled:{},
    multiple: {
      default: false
    },
    useId:{
      default: false
    },
    filter: {},
    label: {
      default: 'Conta'
    },
    appendToBody: {}
  },
  data () {
    return {
    }
  },
  computed: {
    account: {
      get () {
        if (Array.isArray(this.value) && this.value.length) {
          return this.value.map(e => e.id)
        }
        if (this.useId) { // no v-model foi passado um ID
          return this.value
        } else {
          return this.value && this.value.id ? this.value.id : null
        }
      },
      set (value) {
        if (this.useId) { // no v-model foi passado um ID
          this.$emit('input', value)
        } else  {  // no v-model foi passado um objeto
          if (this.multiple) {
            this.$emit('input', this.allAccounts.filter(e => value.includes(e.id)))
          }
          this.$emit('input', this.allAccounts.find(e => e.id === value))
        }
      }
    },
    allAccounts () {
      return this.$store.state.account.accountsSelect
    },
    allOptions () {
      return this.$store.state.account.accountsSelect
    },
    options () {
      if (this.filter) { // filtrar
        // filtro por tipo de pagamento
        if (this.filter.typePayment !== undefined) {
          const accounts = this.allAccounts.filter(e => e.type_payments.some(t => t.id === this.filter.typePayment))
          if (accounts.length) {
            return accounts.map(e => ({value: e.id, label: e.account }))
          }
          return []
        }
      }
      return this.$store.getters['account/forSelect']
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('account')
    }
  },
  created () {
    if (!this.allOptions.length) {
      this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })
    }
  }
}
</script>
