<template>
  <div class="w-full">
    <label class="vs-input--label">Tipo de Pagamento</label>
    <v-select
      v-model="typePayment"
      :clearable="false"
      :filterable="false"
      :reduce="(option) => option.value"
      :options="options"
      data-vv-as="Tipo de Pagamento"
      v-validate.initial="'required'"
      name="typePayment"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :disabled="disabled"
      :appendToBody="appendToBody"
    >
      <template #no-options="{}"> Nenhum resultado encontrado. </template>
    </v-select>
    <span class="text-danger text-sm" v-show="errors.has('typePayment')">{{
      errors.first("typePayment")
    }}</span>
  </div>
</template>

<script>
// Store Module
import axios from '@/axios.js'

export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    appendToBody: {},
    disabled:{},
    multiple: {
      default: false
    },
    useId: {
      default: false
    }
  },
  data () {
    return {
      options:  []
    }
  },
  computed: {
    typePayment: {
      get () {
        if (Array.isArray(this.value) && this.value.length) {
          return this.value.map(e => e.id)
        }
        if (this.useId) { // no v-model foi passado um ID
          return this.value
        } else {
          return this.value && this.value.id ? this.value.id : null
        }
      },
      set (value) {

        if (this.useId) { // no v-model foi passado um ID
          this.$emit('input', value)
        } else  {  // no v-model foi passado um objeto
          if (this.multiple) {
            this.$emit('input', this.data.filter(e => value.includes(e.id)))
          }
          this.$emit('input', this.data.find(e => e.id === value))
        }


      }
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('typePayment')
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_API_URL}/typePayments`)
        .then((response) => {
          for (const typePayment of response.data) {
            this.data = response.data
            this.options.push({
              value: typePayment.id,
              label: typePayment.type_payment
            })
          }
        })
    }
  },
  created () {
    this.fetch()
  }
}
</script>
