var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("label", { staticClass: "vs-input--label" }, [
        _vm._v("Tipo de Pagamento"),
      ]),
      _c("v-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate.initial",
            value: "required",
            expression: "'required'",
            modifiers: { initial: true },
          },
        ],
        attrs: {
          clearable: false,
          filterable: false,
          reduce: (option) => option.value,
          options: _vm.options,
          "data-vv-as": "Tipo de Pagamento",
          name: "typePayment",
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
          disabled: _vm.disabled,
          appendToBody: _vm.appendToBody,
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({}) {
              return [_vm._v(" Nenhum resultado encontrado. ")]
            },
          },
        ]),
        model: {
          value: _vm.typePayment,
          callback: function ($$v) {
            _vm.typePayment = $$v
          },
          expression: "typePayment",
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("typePayment"),
              expression: "errors.has('typePayment')",
            },
          ],
          staticClass: "text-danger text-sm",
        },
        [_vm._v(_vm._s(_vm.errors.first("typePayment")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }